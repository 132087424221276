import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Fuse from "fuse.js";

import songMeta from "../data/miniSongList.json";
import { getHymnBook, SongType } from "../utils/constants";
import SongSearchSelect from "./MinSongSearchSelect";
import { SongTypeContext } from "../utils/utils";

const REG_FUSE_OPTIONS = {
  includeScore: true,
  threshold: 0.4,
  keys: [
    "name",
    "hymn",
    "pageNumber",
    "fullBookName",
    "bookAndPage",
    "fullBookAndPage",
  ],
};

/**
 * SearchBar for the mini song app. Only supports searching by name, book
 */
const SearchBar = () => {
  const navigate = useNavigate();
  const [_, toggleMenuOpen] = useState(false);
  const { songType } = useContext(SongTypeContext);

  /**
   * Called whenever the Select bar is opened
   */
  const handleMenuOpen = () => {
    toggleMenuOpen(true);
  };

  /**
   * Called whenever the Select bar is closed
   */
  const handleMenuClose = () => {
    toggleMenuOpen(false);
  };

  /**
   * Method that handles when user clicks on a selectable item in the search bar.
   *
   * @param e
   * @returns
   */
  const changeVal = (e) => {
    if (e == null) {
      return;
    }

    navigate(
      `/songs/${e?.data?.songType}/${e?.data?.hymn}_${e?.data?.pageNumber}`
    );
  };

  const songList = Object.values(songMeta?.songs);
  const songSearches = songList.map((song) => ({
    name: song?.name,
    hymn: song?.hymn,
    pageNumber: song?.pageNumber,
    songType: song?.songType,
    fullBookName: getHymnBook(song?.hymn),
    bookAndPage: `${song?.hymn} ${song.pageNumber}`,
    fullBookAndPage: `${getHymnBook(song?.hymn)} ${song?.pageNumber}`,
  }));

  let englishSongs = songSearches.filter((song) => song.songType === "english");
  let chineseSongs = songSearches.filter((song) => song.songType === "chinese");

  const englishFuse = new Fuse(englishSongs, REG_FUSE_OPTIONS);
  const chineseFuse = new Fuse(chineseSongs, REG_FUSE_OPTIONS);

  const defaultEnglishSongOptions = englishSongs.map((res) => ({
    label: res.name,
    value: res.name,
    data: res,
  }));
  const defaulChineseSongOptions = chineseSongs.map((res) => ({
    label: res.name,
    value: res.name,
    data: res,
  }));

  const promiseOptions = (inputValue: string): any => {
    let results = songType == SongType.english ? englishFuse.search(inputValue) : chineseFuse.search(inputValue);
    // limit # of results to 50 for faster ui
    results = results.slice(0, Math.min(results.length, 50));

    // regular search
    return new Promise((resolve) => {
      resolve(
        results.map((res) => ({
          label: res.item.name,
          value: res.item.name,
          data: res.item,
        }))
      );
    });
  };

  return (
    <div className="mt-2">
      <SongSearchSelect
        promiseOptions={promiseOptions}
        onChange={changeVal}
        handleMenuOpen={handleMenuOpen}
        handleMenuClose={handleMenuClose}
        defaultEnglishSongOptions={defaultEnglishSongOptions}
        defaultChineseSongOptions={defaulChineseSongOptions}
      />
      <p className="text-light-gray text-[12px]">
        Search by title, book (shortcut or full name), page number. Try `Songs
        of love 1 16`
      </p>
    </div>
  );
};

export default SearchBar;
